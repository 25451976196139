import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import type { DashboardFilterObjectTypes } from 'bundles/Shared/entities/dashboard/model/types/types';
import pluralize from 'pluralize';
import { capitalize } from 'lodash-es';
import { DashboardFilterObjectIcon } from '@/bundles/Shared/entities/dashboard/ui/DashboardFilterObjectIcon';

export const DashboardFilterObjectItem = ({
  disabled,
  type,
  count,
  ...props
}: {
  type: DashboardFilterObjectTypes;
  count: number;
  disabled?: boolean;
} & Pick<React.HTMLAttributes<HTMLButtonElement>, 'onClick'>) => {
  return (
    <button
      type="button"
      className={cn(
        'group flex cursor-pointer flex-col items-center justify-center gap-2 !rounded-lg border-2 border-neutral-100 bg-white p-2 text-neutral-550',
        'hover:bg-neutral-100',
        'disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400',
      )}
      disabled={disabled}
      {...props}
    >
      <DashboardFilterObjectIcon type={type} />
      <span className="secondary-semibold">
        {count} {pluralize(capitalize(type), count)}
      </span>
    </button>
  );
};
