import ErrorBoundary from '@/app/ui/ErrorBoundary';
import { SettingsReportWidgetConfigEditor } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/Widget/SettingsReportDashboardWidgetConfigEditor';
import { DashboardLayout } from '@/bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { WidgetFlyPanel } from '@/bundles/Shared/components/layouts/dashboard/WidgetLayout';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { DashboardContext } from '@/bundles/Shared/entities/dashboard/lib';
import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard/model/types/types';
import {
  getSelectorReportBuilderTemplateWidgetState,
  updateReportBuilderTemplateWidgetState,
} from '@/bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import { ReportDashboardDateFilterBlock } from '@/bundles/Shared/features/dashboard/filter/byDate/ui/ReportDashboardDateFilter';
import { ReportBuilderTemplateEagleEyeObjectsFilter } from '@/bundles/Shared/features/dashboard/filter/byObject/ui/ReportBuilderTemplateEagleEyeObjectsFilter';
import { ReportBuilderTemplateObjectLevelObjectsFilter } from '@/bundles/Shared/features/dashboard/filter/byObject/ui/ReportBuilderTemplateObjectLevelObjectsFilter';
import { useUpdateWidgetConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import {
  ReportBuilderTemplateContext,
  ReportingWidgetGroupIdContext,
  useReportBuilderTemplateContext,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import {
  ReportBuilderTemplateWidgetContext,
  useReportBuilderTemplateWidgetContext,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateWidgetContext';
import {
  JsonEditorUI,
  useJsonEditorButtonFeature,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/jsonEditorButtonFeature';
import { WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { WidgetErrorBoundaryFallback } from '@/bundles/Shared/widgets/dashboard/widgetsHelpers/ui/WidgetErrorBoundary';
import { FormulasAndVariablesWorkspace } from '@/bundles/Shared/widgets/formula/panel';
import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import {
  ReportBuilderTemplateMetaContext,
  useReportBuilderTemplateMetaContext,
} from '@/entities/report/reportBuilder/context/reportBuilderTemplateMetaContext';
import {
  useFindReportBuilderTemplateWidget,
  useGetReportBuilderTemplate,
  useGetReportBuilderTemplateMetaWithSearchParamAssetId,
  useGetReportBuilderTemplateWidgetSection,
  useGetReportBuilderTemplateWidgetSectionPreview,
} from '@/entities/report/reportBuilder/lib';
import { useGetSelectedObjectsSearchQuery } from '@/entities/report/reportBuilder/lib/useGetApiReportBuilderTemplatePreviewPdfQuery';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import { ReactComponent as WidgetSvg } from 'bundles/Shared/components/layouts/dashboard/WidgetIcon.svg';
import type { UnknownRecord } from 'type-fest';

const SettingsReportBuilderTemplateWidget = ({
  template,
}: {
  template: ReportBuilderTemplateDto;
}) => {
  const widget = useReportBuilderTemplateWidgetContext();
  const templateCtx = useReportBuilderTemplateContext()!;
  const templateKind = useReportingEntityKindContext();
  const meta = useReportBuilderTemplateMetaContext();
  const jsonEditoButtonFeature = useJsonEditorButtonFeature();

  const dispatch = useAppDispatch();
  const [updateConfig] = useUpdateWidgetConfig(widget.widgetType);

  useGetReportBuilderTemplateWidgetSection();

  const widgetSectionState = useAppSelector(
    getSelectorReportBuilderTemplateWidgetState(
      templateCtx.templateId,
      widget.groupId,
      widget.id,
    ),
  );
  const templateStateDate = useAppSelector(
    (state) =>
      state.reportBuilderTemplate.entities[templateCtx.templateId]?.date,
  );
  // XYChartWidget expects data to be in data prop
  const context = {
    assets: meta.assets,
    segments: meta.segments,
  };

  const payloadParams = useGetSelectedObjectsSearchQuery();

  const {
    isLoading: isWidgetDataLoading,
    isFetching: isWidgetDataFetching,
    isError: isWidgetDataError,
    data: widgetData,
  } = useGetReportBuilderTemplateWidgetSectionPreview();

  const { ConfigComponent, Component, title } =
    WIDGETS_CONFIG_MAP[widget.widgetType];

  const handleStateChange = (newState: UnknownRecord) => {
    dispatch(
      updateReportBuilderTemplateWidgetState({
        id: widget.id,
        groupId: widget.groupId,
        templateId: templateCtx.templateId,
        widgetState: newState,
      }),
    );
  };

  return (
    <FormulasAndVariablesWorkspace>
      <DashboardLayout className="h-full">
        <DashboardLayout.Header className="h-auto bg-neutral-000">
          <DashboardLayout.Header.Title
            classes={{
              subtitle: 'flex items-center gap-2 text-neutral-550',
            }}
            subtitle={template.name}
            title={widget.title}
          />
        </DashboardLayout.Header>
        <DashboardLayout.Body className="relative">
          <DashboardLayout.Grid>
            <jsonEditoButtonFeature.ButtonWrapper>
              {templateKind === 'eagle_eye' && (
                <ReportBuilderTemplateEagleEyeObjectsFilter />
              )}
              {templateKind === 'object_level' && (
                <>
                  <ReportBuilderTemplateObjectLevelObjectsFilter />
                  <ReportDashboardDateFilterBlock date={templateStateDate} />
                </>
              )}
              <jsonEditoButtonFeature.Button />
            </jsonEditoButtonFeature.ButtonWrapper>
            <JsonEditorUI
              editor={<SettingsReportWidgetConfigEditor />}
              isCodeEditorOpen={jsonEditoButtonFeature.isCodeEditorOpen}
            >
              <GrowDiv>
                <ErrorBoundary
                  fallback={
                    <WidgetErrorBoundaryFallback title={widget.title} />
                  }
                >
                  <Component
                    widgetId={widget.id}
                    className="h-[500px]"
                    widgetSection={widget}
                    context={context}
                    mode="edit"
                    state={{
                      ...widgetSectionState,
                      // XYChartWidget expects data to be in data prop
                      assets: payloadParams.assetIds,
                      segments: payloadParams.segmentIds,
                    }}
                    onStateChange={handleStateChange}
                    settings={widget.widgetConfig}
                    onSettingsChange={(config) => updateConfig({ config })}
                    data={widgetData?.widget}
                    isLoading={isWidgetDataLoading}
                    isError={isWidgetDataError}
                    isFetching={isWidgetDataFetching}
                  />
                </ErrorBoundary>
              </GrowDiv>
            </JsonEditorUI>
          </DashboardLayout.Grid>

          <WidgetFlyPanel className="top-6">
            <WidgetFlyPanel.Header
              label="Widget: "
              title={title}
              icon={<WidgetSvg />}
            />
            {ConfigComponent && <ConfigComponent />}
          </WidgetFlyPanel>
        </DashboardLayout.Body>
      </DashboardLayout>
    </FormulasAndVariablesWorkspace>
  );
};

export const ReportBuilderTemplateSettingsWidget = () => {
  const templateKind = useReportingEntityKindContext();
  const { data: templateData, isLoading: isTemplateLoading } =
    useGetReportBuilderTemplate();

  const { data: metaData, isLoading: isMetaDataLoading } =
    useGetReportBuilderTemplateMetaWithSearchParamAssetId();
  const isLoading = isMetaDataLoading || isTemplateLoading;

  const widget = useFindReportBuilderTemplateWidget(templateData);

  if (isLoading) {
    return <AnimationLoader className="static min-h-screen" />;
  }

  if (templateData == null || widget == null || metaData == null) {
    return <NoDataOverlay title="Not found" />;
  }
  return (
    <DashboardContext.Provider
      value={{
        boardId: '',
        dashboardId: '',
        boardSlug: '',
        dashboardSlug: '',
        dashboardType:
          templateKind === 'eagle_eye'
            ? ReportDashboardType.REPORT_BUILDER_TEMPLATE_EAGLE_EYE
            : ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      }}
    >
      <ReportBuilderTemplateContext.Provider
        value={{
          widgetId: widget.id,
          templateId: templateData.id,
        }}
      >
        <ReportingWidgetGroupIdContext.Provider value={widget.groupId}>
          <ReportBuilderTemplateWidgetContext.Provider value={widget}>
            <ReportBuilderTemplateMetaContext.Provider value={metaData}>
              <SettingsReportBuilderTemplateWidget template={templateData} />
            </ReportBuilderTemplateMetaContext.Provider>
          </ReportBuilderTemplateWidgetContext.Provider>
        </ReportingWidgetGroupIdContext.Provider>
      </ReportBuilderTemplateContext.Provider>
    </DashboardContext.Provider>
  );
};
