import { reportBuilderTemplateSliceActions } from '@/bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import { FilterIconButton } from '@/bundles/Shared/features/dashboard/filter/byAsset/ui/FilterIconButton';
import { ReportDashboardFilterByObjectModal } from '@/bundles/Shared/features/dashboard/filter/byObject/ui/ReportDashboardFilterByObjectModal';
import { useReportBuilderTemplateMetaContext } from '@/entities/report/reportBuilder/context/reportBuilderTemplateMetaContext';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { FilterBlock } from 'bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportObjectLevelDashboard';

export function ReportBuilderTemplateObjectLevelObjectsFilter() {
  const objectLevelAssetId = useAppSelector(
    (state) => state.reportBuilderTemplate.objectLevelAssetId,
  );
  const dispatch = useAppDispatch();
  const { assets } = useReportBuilderTemplateMetaContext();
  const { openModal } = useModal();

  const handleChange = async () => {
    const res = await openModal(ReportDashboardFilterByObjectModal, {
      assets,
    });
    if (res == null || Array.isArray(res)) {
      return;
    }

    dispatch(reportBuilderTemplateSliceActions.updateObjectLevelAssetId(res));
  };

  return (
    <FilterBlock
      iconName="asset"
      label="Asset"
      value={
        assets.find((a) => a.id === objectLevelAssetId)?.name ?? 'Select Asset'
      }
    >
      <FilterIconButton onClick={handleChange} />
    </FilterBlock>
  );
}
