import { LegalEntitiesCounter } from 'bundles/Shared/entities/legalEntity/ui/LegalEntityCounter';
import pluralize from 'pluralize';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import { LegalEntity } from '@/entities/core/legalEntity';
import React from 'react';

interface Props {
  legalEntities: Pick<LegalEntity, 'id' | 'name'>[];
}

// move to shared/entities/ui
export function LEList({ legalEntities }: Props) {
  return (
    <div className="flex flex-col gap-1">
      <span className="leading-1 text-[12px] font-normal">
        Legal {pluralize('Entity', legalEntities.length)}
      </span>
      <ul className="flex flex-col gap-1 ">
        {legalEntities.map((le) => (
          <li key={le.id} className="before:content-['•'] before:mr-1 ml-1">
            {le.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

function LegalEntitiesIconWithTooltip({
  legalEntities,
  ...props
}: Props &
  Omit<React.ComponentProps<typeof LegalEntitiesCounter>, 'legalEntityCount'>) {
  return (
    <Tooltip
      arrowPosition="start"
      placement="right-start"
      mainText={<LEList legalEntities={legalEntities} />}
    >
      <LegalEntitiesCounter
        legalEntityCount={legalEntities.length}
        {...props}
      />
    </Tooltip>
  );
}

export default LegalEntitiesIconWithTooltip;
