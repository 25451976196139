import { useDashboardContext } from 'bundles/Shared/entities/dashboard/lib';

import {
  ReportDashboardAssetFilter,
  ReportDashboardAssetFilterPropsProps,
} from '@/bundles/Shared/features/dashboard/filter/byAsset/ui/ReportDashboardAssetFilter';
import { useReportObjectDashboardFilterObjects } from '@/bundles/Shared/entities/dashboard/api';

export const ObjectDashboardAssetFilter = (
  props: Omit<ReportDashboardAssetFilterPropsProps, 'assets'>,
) => {
  const { dashboardId } = useDashboardContext();
  const { objects } = useReportObjectDashboardFilterObjects({
    dashboardId,
  });

  return <ReportDashboardAssetFilter assets={objects ?? []} {...props} />;
};
