import { isEqual } from 'lodash-es';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import { useReportDashboardFavoriteFilterObjects } from '@/bundles/Shared/entities/dashboard/api';

export const useDashboardFavoriteAssets = (
  key: ReportDashboardType.OBJECT | ReportDashboardType.COMPARISON_MODE,
) => {
  const [favouriteItems, setFavouriteItems] =
    useReportDashboardFavoriteFilterObjects({
      dashboardType: key,
    });

  const toggleItemFavorite = (item: {
    dashboardId: string;
    assetId: number;
  }) => {
    setFavouriteItems(
      favouriteItems.some((itemX) => isEqual(itemX, item))
        ? favouriteItems.filter((itemX) => !isEqual(itemX, item))
        : [...favouriteItems, item],
    );
  };

  const isItemFavorite = (item: { dashboardId: string; assetId: number }) =>
    favouriteItems.some((itemX) => isEqual(itemX, item));

  return {
    toggleItemFavorite,
    isItemFavorite,
    favouriteItems,
  };
};

export const useFavoriteObjectDashboardAssets = () => {
  return useDashboardFavoriteAssets(ReportDashboardType.OBJECT);
};
export const useFavoriteComparisonDashboardAssets = () => {
  return useDashboardFavoriteAssets(ReportDashboardType.COMPARISON_MODE);
};
