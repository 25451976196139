import { ReportDashboardSingleObjectPickerDropdown } from '@/bundles/Shared/features/dashboard/filter/byObject/ui/ReportDasbhoardObjectPicker';
import type { DashboardFilterObject } from 'bundles/Shared/entities/dashboard/model/types/types';
import { useReportDashboardNavigateToAsset } from '@/shared/lib/hooks/navigation/dashboardsNavitation';

export interface ReportDashboardAssetFilterPropsProps {
  assetId: number | string | null;
  assets: DashboardFilterObject[];
}

export function ReportDashboardAssetFilter({
  assetId,
  assets,
  ...props
}: ReportDashboardAssetFilterPropsProps &
  Omit<
    React.ComponentProps<typeof ReportDashboardSingleObjectPickerDropdown>,
    'value' | 'onChange' | 'options'
  >) {
  const navigateToAsset = useReportDashboardNavigateToAsset();

  return (
    <ReportDashboardSingleObjectPickerDropdown
      value={assets.find((asset) => asset.id === Number(assetId)) ?? null}
      onChange={(value) => navigateToAsset(value.id)}
      objectTypes={['asset', 'all']}
      options={assets}
      {...props}
    />
  );
}
