import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import {
  ExtractParams,
  generateUrl,
  ROUTES_ROOT,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import {
  NavigateOptions,
  useLocation,
  useNavigate,
  useParams,
} from '@reach/router';
import {
  EagleEyeBoard,
  ReportDashboardAsset,
} from 'bundles/Shared/entities/dashboard/model/types/types';

import { useCallback, useEffect } from 'react';
import { intersectionBy, orderBy, sortBy } from 'lodash-es';
import { useGetApiReportObjectDashboardsByIdQuery } from '@/shared/api/dashboardSettingsEnhancedApi';

import {
  useFavoriteComparisonDashboardAssets,
  useFavoriteObjectDashboardAssets,
} from 'bundles/Shared/features/dashboard/filter/favorite/lib';
import { ComparisonDashboardDto } from '@/shared/api/dashboardsGeneratedApi';
import { DashboardFilterObject } from '@/bundles/Shared/entities/dashboard/model/types/types';

export const useReportSettingsDashboardScreenParams = () =>
  useParams<
    ExtractParams<
      typeof ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath
    >
  >();
export const useReportBuilderTemplateScreenParams = () =>
  useParams<
    ExtractParams<
      typeof ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.fullPath
    >
  >();
export const useReportSettingsDashboardScreenQueryParams = () => {
  const [searchParams] = useSearchParams();
  return {
    boardId: searchParams.get('boardId') ?? undefined,
    assetId: searchParams.get('assetId') && Number(searchParams.get('assetId')),
  };
};
export const useReportDashboardSettingsScreenQueryParams = () => {
  const [searchParams] = useSearchParams();
  const assetId = searchParams.get('assetId');
  const boardId = searchParams.get('boardId');
  return {
    assetId: assetId && Number(assetId),
    boardId,
  };
};

export const useNavigateToDashboardSettingsBySlug = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (slug: string) => {
      navigate(
        generateUrl(ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath, {
          pathParams: {
            dashboardId: slug,
          },
          queryParams: {
            ...Object.fromEntries(new URLSearchParams(location.search)),
          },
        }),
        {
          replace: true,
        },
      );
    },
    [navigate, location.search],
  );
};

export const useNavigateToWidget = () => {
  const navigate = useNavigate();
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  const { boardId } = useReportDashboardSettingsScreenQueryParams();

  const { assetId: selectedAssetId } =
    useReportDashboardSettingsScreenQueryParams();
  return ({
    widgetId,
    assetId,
  }: {
    widgetId: string;
    assetId?: number | string;
  }) =>
    navigate(
      generateUrl(
        ROUTES_ROOT.settings.report.dashboards.dashboard.widget.fullPath,
        {
          pathParams: {
            dashboardId,
            widgetId,
            boardId,
          },
          queryParams: {
            assetId: assetId ?? selectedAssetId,
          },
        },
      ),
    );
};

export const useNavigateToDasbhoardViewToBoard = () => {
  const [_, setSearchParams] = useSearchParams();

  const navigateToBoard = (board: Pick<EagleEyeBoard, 'id' | 'slug'>) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set('boardId', board.slug);
      return params;
    });
  };

  return navigateToBoard;
};

export const useNavigateDashboardSettingsToBoard = () => {
  const [_, setSearchParams] = useSearchParams();

  return useCallback(
    (board: Pick<EagleEyeBoard, 'id' | 'slug'>) => {
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        params.set('boardId', board.slug);
        return params;
      });
    },
    [setSearchParams],
  );
};
export const useNavigateToFirstBoardEffect = (
  boards: Pick<EagleEyeBoard, 'id' | 'slug'>[] | undefined,
) => {
  const navigateToBoard = useNavigateDashboardSettingsToBoard();
  const { boardId } = useReportSettingsDashboardScreenQueryParams();

  useEffect(() => {
    if (boards == null || boards.length === 0 || boardId) {
      return;
    }
    navigateToBoard(boards[0]);
  }, [boards, boardId]);
};
export const useNavigateToFirstAssetEffect = ({
  assets,
}: {
  assets: Pick<ReportDashboardAsset, 'id'>[];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const assetId = searchParams.get('assetId');

  useEffect(() => {
    if (assetId != null || assets[0] == null) {
      return;
    }
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set('assetId', assets[0].id.toString());
      return params;
    });
  }, [assets]);
};

export const useReportDashboardNavigateToAsset = () => {
  const [_, setSearchParams] = useSearchParams();

  return (assetId: number | string) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set('assetId', assetId.toString());
      return params;
    });
  };
};

export const useNavigateToFirstAssetAndBoardEffect = ({
  assets,
  boards,
}: {
  assets: ReportDashboardAsset[];
  boards: Pick<EagleEyeBoard, 'id' | 'slug'>[];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const assetId = searchParams.get('assetId');
    const boardId = searchParams.get('boardId');
    if (assetId != null && boardId != null) {
      return;
    }
    const newSearchParams = new URLSearchParams(searchParams);
    if (boards.length > 0 && boardId == null) {
      newSearchParams.set('boardId', boards[0].slug);
    }
    if (assets.length > 0 && assetId == null) {
      newSearchParams.set('assetId', assets[0].id.toString());
    }
    setSearchParams(newSearchParams);
  }, [assets, boards]);
};
export const useReportDashboardScreenQueryParams = () => {
  const { assetId, boardId } = useQueryParams<{
    assetId?: string;
    boardId?: string;
  }>();
  return {
    assetId: assetId && Number(assetId),
    boardId,
  };
};
export const useReportDashboardFromScreenParams = () => {
  const { dashboardId } =
    useParams<ExtractParams<typeof ROUTES_ROOT.report.dashboards.fullPath>>();

  return useGetApiReportObjectDashboardsByIdQuery({
    id: dashboardId,
  });
};
export const useReportDashboardNavigate = () => {
  const navigate = useNavigate();
  const { dashboardId } =
    useParams<ExtractParams<typeof ROUTES_ROOT.report.dashboards.fullPath>>();
  const { boardId: currentBoardId } = useReportDashboardScreenQueryParams();
  return (
    {
      assetId,
      boardId = currentBoardId,
    }: {
      assetId: string;
      boardId?: string;
    },
    options?: NavigateOptions<unknown>,
  ) =>
    navigate(
      generateUrl(ROUTES_ROOT.report.dashboards.fullPath, {
        pathParams: {
          dashboardId,
        },
        queryParams: {
          assetId,
          boardId,
        },
      }),
      options,
    );
};
export const useReportDashboardRedirectToFirstOrFavoriteAssetEffect = () => {
  const { data } = useReportDashboardFromScreenParams();
  const { assetId, boardId } = useReportDashboardScreenQueryParams();
  const navigateToDashboard = useReportDashboardNavigate();
  const { favouriteItems } = useFavoriteObjectDashboardAssets();

  useEffect(() => {
    const newBoardId = boardId ?? data?.boards[0]?.slug;
    if (data != null && assetId == null) {
      if (
        favouriteItems.filter((item) => item.dashboardId === data.id).length > 0
      ) {
        navigateToDashboard(
          {
            assetId: favouriteItems[0].assetId.toString(),
            boardId: newBoardId,
          },
          {
            replace: true,
          },
        );
        return;
      }
      navigateToDashboard(
        {
          assetId: orderBy(data.assets, 'name')[0].id.toString(),
          boardId: newBoardId,
        },
        {
          replace: true,
        },
      );
    }
  }, [data]);
};
export const useNavigateToInitialStateComparisonDashboardEffect = ({
  compareObjs,
  comparisonDashboardData: data,
  callbackWithFirstObjects,
}: {
  compareObjs: DashboardFilterObject[];
  comparisonDashboardData: ComparisonDashboardDto | undefined;
  callbackWithFirstObjects: (objs: DashboardFilterObject[]) => void;
}) => {
  const { favouriteItems } = useFavoriteComparisonDashboardAssets();
  const navigate = useNavigate();

  const { dashboardId } =
    useParams<
      ExtractParams<typeof ROUTES_ROOT.report.comparisonDashboards.fullPath>
    >();

  const { boardId } = useQueryParams();

  useEffect(() => {
    if (
      compareObjs.length > 1 &&
      compareObjs.at(0)?._dashboardId === dashboardId
    )
      return;
    if (data == null || dashboardId == null || data.assets.length === 0) return;
    if (data.assets[0]?.id == null || data.boards[0]?.id == null) return;

    navigate(
      generateUrl(ROUTES_ROOT.report.comparisonDashboards.fullPath, {
        pathParams: {
          dashboardId,
        },
        queryParams: {
          boardId: boardId ?? data.boards[0].slug,
        },
      }),
      {
        replace: true,
      },
    );
    const START_LENGTH_OF_COMPARE_OBJS = 2;

    let firstObjects = intersectionBy(
      data.assets,
      favouriteItems
        .filter((favItem) => favItem.dashboardId === dashboardId)
        .map((favItem) => ({ id: favItem.assetId })),
      'id',
    ).slice(0, START_LENGTH_OF_COMPARE_OBJS);

    const sortedAssets = sortBy(data.assets, 'name');

    firstObjects = firstObjects.concat(
      sortedAssets.slice(0, START_LENGTH_OF_COMPARE_OBJS),
    );
    const firstObjectsWithType = firstObjects
      .slice(0, START_LENGTH_OF_COMPARE_OBJS)
      .map((a) => ({
        ...a,
        type: 'asset',
        dashboardId,
      })) satisfies DashboardFilterObject[];

    callbackWithFirstObjects(firstObjectsWithType);
  }, [data]);
};
