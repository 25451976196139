import { createSelector } from '@reduxjs/toolkit';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';

import type {
  DashboardFilterObject,
  ReportDashboardAsset,
  ReportDashboardEagleEyeAsset,
  ReportDashboardSectionPositionWithId,
  ReportDashboardSegment,
  ReportEagleEyeDashboard,
} from 'bundles/Shared/entities/dashboard/model/types/types';

import {
  useGetApiReportScoreboardsByIdQuery,
  useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
  useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
  useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
  useGetApiSettingsReportComparisonDashboardsMetaQuery,
  useGetApiSettingsReportEagleEyeDashboardsByIdQuery,
  useGetApiSettingsReportEagleEyeDashboardsMetaQuery,
  useGetApiSettingsReportObjectDashboardsMetaQuery,
  useGetApiReportComparisonDashboardsByIdQuery,
  useGetApiReportObjectDashboardsByIdQuery,
} from '@/shared/api/dashboardSettingsEnhancedApi';

import { LeClassification } from 'bundles/Shared/entities/leClasssification';
import {
  DashboardlayoutDto,
  GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse,
  GetApiSettingsReportEagleEyeDashboardsFilterSetsMetaApiResponse,
  GetApiSettingsReportEagleEyeDashboardsMetaApiResponse,
} from '@/shared/api/dashboardsSettingsGeneratedApi';
import { orderBy, uniqBy } from 'lodash-es';
import { useMemo } from 'react';
import { useGetApiSettingsReportEagleEyeDashboardsFilterSetsMetaQuery } from '@/shared/api/dashboardSettingsFilterSetsEnhancedApi';
import { useLocalStorageValue } from '@/shared/lib/hooks/useLocalStorageValue';

export const mapAssetToFilterObject = (
  asset: Pick<ReportDashboardAsset, 'id' | 'name'> &
    Partial<Pick<ReportDashboardAsset, 'legalEntities'>>,
): DashboardFilterObject => ({
  id: asset.id,
  name: asset.name,
  legalEntities: asset.legalEntities ?? [],
  leClassification: [
    ...new Set(
      asset.legalEntities?.map((le) => le.classification).filter(Boolean),
    ),
  ] as LeClassification[],
  type: 'asset',
});

export const mapSegmentToFilterObject = (
  segment: ReportDashboardSegment,
): DashboardFilterObject => ({
  id: segment.id,
  name: segment.title,
  type: 'segment',
  legalEntities: segment.legalEntities ?? [],
  leClassification: [
    ...new Set(
      segment.legalEntities?.map((le) => le.classification).filter(Boolean),
    ),
  ] as LeClassification[],
});

const useReportDashboardSelectOrderedByName = () => {
  return useMemo(() => {
    const emptyArray: ReportDashboardAsset[] = [];
    return createSelector(
      (state: { data?: { assets?: ReportDashboardAsset[] } } | undefined) =>
        state?.data?.assets ?? emptyArray,
      (assets: ReportDashboardAsset[]) => orderBy(assets, 'name'),
    );
  }, []);
};
const useSelectedLegalEntitiesFromAssets = () => {
  return useMemo(() => {
    return createSelector(
      (
        state:
          | { data?: { assets?: ReportDashboardEagleEyeAsset[] } }
          | undefined,
      ) => {
        return state?.data?.assets ?? [];
      },
      (assets: ReportDashboardEagleEyeAsset[]) => {
        return assets.flatMap((asset) =>
          asset.legalEntities.map((le) => ({ ...le, asset })),
        );
      },
    );
  }, []);
};

const useReportDashboardSelectObjects = () => {
  return useMemo(() => {
    const emptyArray: DashboardFilterObject[] = [];
    return createSelector(
      (state: { data: { assets: ReportDashboardAsset[] } }) =>
        state?.data?.assets ?? emptyArray,
      (assets: ReportDashboardAsset[]) =>
        assets.map(mapAssetToFilterObject) ?? emptyArray,
    );
  }, []);
};

export const useReportObjectDashboardAssetsQuery = () => {
  const selectOrderedByName = useReportDashboardSelectOrderedByName();
  const selectObjects = useReportDashboardSelectObjects();
  const selectLegalEntitiesFromAssets = useSelectedLegalEntitiesFromAssets();

  return useGetApiSettingsReportObjectDashboardsMetaQuery(undefined, {
    selectFromResult: ({ data, ...result }) => {
      return {
        ...result,
        assets: (data?.assets ?? []) as ReportDashboardAsset[],
        reportBuilderTemplates: data?.reportBuilderTemplates ?? [],
        assetObjects: selectObjects({ data }),
        assetsOrderedByName: selectOrderedByName({ data }),
        legalEntities: selectLegalEntitiesFromAssets({ data }),
      };
    },
  });
};
export const useReportComparisonDashboardAssetsQuery = () => {
  const selectOrderedByName = useReportDashboardSelectOrderedByName();
  const selectObjects = useReportDashboardSelectObjects();

  return useGetApiSettingsReportComparisonDashboardsMetaQuery(undefined, {
    selectFromResult: ({ data, ...result }) => {
      return {
        ...result,
        assets: (data?.assets ?? []) as ReportDashboardAsset[],
        assetObjects: selectObjects({ data }),
        assetsOrderedByName: selectOrderedByName({ data }),
      };
    },
  });
};

export const useReportEagleEyeDashboardFiltersObjectQuery = () => {
  const selectObjects = useMemo(() => {
    const emptyArray: DashboardFilterObject[] = [];
    return createSelector(
      (state: {
        data: GetApiSettingsReportEagleEyeDashboardsFilterSetsMetaApiResponse;
      }) => state?.data,
      (
        data: GetApiSettingsReportEagleEyeDashboardsFilterSetsMetaApiResponse,
      ) =>
        data
          ? ([
              ...data.assets.map(mapAssetToFilterObject),
              ...data.segments.map(mapSegmentToFilterObject),
            ] as DashboardFilterObject[])
          : emptyArray,
    );
  }, []);
  return useGetApiSettingsReportEagleEyeDashboardsFilterSetsMetaQuery(
    undefined,
    {
      selectFromResult: ({ data, ...result }) => {
        return {
          ...result,
          objects: selectObjects({ data }),
        };
      },
    },
  );
};

export const useReportEagleEyeDashboardAssetsQuery = () => {
  const selectObjects = useMemo(() => {
    const emptyArray: DashboardFilterObject[] = [];
    return createSelector(
      (state: {
        data: GetApiSettingsReportEagleEyeDashboardsMetaApiResponse;
      }) => state?.data,
      (data) =>
        data
          ? ([
              ...data.assets.map(mapAssetToFilterObject),
              ...data.segments.map(mapSegmentToFilterObject),
            ] as DashboardFilterObject[])
          : emptyArray,
    );
  }, []);

  const selectOrderedByName = useMemo(() => {
    const emptyArray: ReportDashboardEagleEyeAsset[] = [];
    return createSelector(
      (
        state:
          | { data?: { assets?: ReportDashboardEagleEyeAsset[] } }
          | undefined,
      ) => state?.data?.assets ?? emptyArray,
      (assets: ReportDashboardEagleEyeAsset[]) => orderBy(assets, 'name'),
    );
  }, []);

  const selectLegalEntitiesFromAssets = useSelectedLegalEntitiesFromAssets();

  return useGetApiSettingsReportEagleEyeDashboardsMetaQuery(undefined, {
    selectFromResult: ({ data, ...result }) => {
      return {
        ...result,
        assets: data?.assets ?? [],
        assetsOrderedByName: selectOrderedByName({ data }),
        legalEntities: selectLegalEntitiesFromAssets({ data }),
        objects: selectObjects({ data }),
      };
    },
  });
};

export const useReportEagleEyeDashboardSettingsById = (
  {
    dashboardId,
  }: {
    dashboardId: ReportEagleEyeDashboard['id'];
  },
  args?: Parameters<
    typeof useGetApiSettingsReportEagleEyeDashboardsByIdQuery
  >[1],
) => {
  return useGetApiSettingsReportEagleEyeDashboardsByIdQuery(
    {
      id: dashboardId,
    },
    {
      ...args,
      selectFromResult: ({ data, ...result }) => {
        return {
          ...result,
          data,
          dashboard: data as unknown as ReportEagleEyeDashboard,
        };
      },
    },
  );
};

export const useReportEagleEyeDashboardById = ({
  dashboardId,
}: {
  dashboardId: ReportEagleEyeDashboard['id'];
}) => {
  return useGetApiReportScoreboardsByIdQuery(
    {
      id: dashboardId,
    },
    {
      selectFromResult: ({ data, ...result }) => {
        return {
          ...result,
          data,
          dashboard: data?.item as unknown as ReportEagleEyeDashboard,
        };
      },
    },
  );
};

export const useDashboardCopyableWidgetSections = ({
  dashboardId,
  boardId,
  dashboardType,
}: {
  dashboardId: string;
  dashboardType: ReportDashboardType;
  boardId?: string;
}) => {
  const query = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery;
      }
      default: {
        return useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery;
      }
    }
  }, [
    dashboardType,
    useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
    useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
    useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
  ]);

  const selectDashboards = useMemo(() => {
    const emptyArray =
      [] as GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse[number]['board']['dashboard'];
    return createSelector(
      ({
        data,
      }: {
        data?: GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse;
      }) => data,
      (sections) =>
        uniqBy(sections?.map((s) => s.board?.dashboard) ?? emptyArray, 'id'),
    );
  }, []);

  const selectBoards = useMemo(() => {
    const emptyArray =
      [] as GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse[number]['board']['dashboard'];
    return createSelector(
      ({
        data,
      }: {
        data?: GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse;
      }) => data,
      (sections) => uniqBy(sections?.map((s) => s.board) ?? emptyArray, 'id'),
    );
  }, []);

  return query(
    {
      boardId: boardId!,
      eagleEyeDashboardId: dashboardId,
      comparisonDashboardId: dashboardId,
      objectDashboardId: dashboardId,
    },
    {
      skip: !boardId,
      selectFromResult: ({ data, ...result }) => {
        const dashboards = selectDashboards({ data });
        return {
          ...result,
          data,
          dashboards,
          dashboardsMap: new Map(dashboards.map((d) => [d.id, d])),
          boards: selectBoards({ data }),
        };
      },
    },
  );
};

export const mapLayoutToLayoutDto = (
  layout: ReportDashboardSectionPositionWithId[],
): DashboardlayoutDto => {
  return layout.map(({ i, type, ...position }) => ({
    id: i,
    position,
    section_type: 'widget',
  })) as DashboardlayoutDto;
};

const DASHBOARD_FAVORITE_ASSETS_KEY = {
  [ReportDashboardType.OBJECT]: 'reportObjectDashboard',
  [ReportDashboardType.COMPARISON_MODE]: 'reportComparisonDashboard',
} as const;

export const useReportDashboardFavoriteFilterObjects = ({
  dashboardType,
}: {
  dashboardType:
    | ReportDashboardType.COMPARISON_MODE
    | ReportDashboardType.OBJECT;
}) => {
  return useLocalStorageValue(DASHBOARD_FAVORITE_ASSETS_KEY[dashboardType], []);
};

export const useReportDashboardFilterObjects = ({
  dashboardId,
  dashboardType,
}: {
  dashboardId: string;
  dashboardType:
    | ReportDashboardType.COMPARISON_MODE
    | ReportDashboardType.OBJECT;
}) => {
  const [favouriteItems] = useReportDashboardFavoriteFilterObjects({
    dashboardType,
  });
  const selectObjects = useMemo(() => {
    const emptyArray: DashboardFilterObject[] = [];
    return createSelector(
      (state: { data: { assets: ReportDashboardAsset[] } }) =>
        state?.data?.assets ?? emptyArray,
      (assets: ReportDashboardAsset[]) =>
        assets.map((asset) => ({
          ...mapAssetToFilterObject(asset),
          isFavorite: favouriteItems.some(
            (item) =>
              item.assetId === asset.id && item.dashboardId === dashboardId,
          ),
        })),
    );
  }, [favouriteItems, dashboardId]);

  const query = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return useGetApiReportObjectDashboardsByIdQuery;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return useGetApiReportComparisonDashboardsByIdQuery;
      }
      default: {
        throw new Error('Invalid dashboard type');
      }
    }
  }, [dashboardType]);

  return query(
    { id: dashboardId },
    {
      skip: dashboardId === '' || dashboardId == null,
      selectFromResult: ({ data, ...result }) => {
        return {
          ...result,
          data,
          objects: selectObjects({ data }),
        };
      },
    },
  );
};

export const useReportObjectDashboardFilterObjects = ({
  dashboardId,
}: {
  dashboardId: string;
}) => {
  return useReportDashboardFilterObjects({
    dashboardId,
    dashboardType: ReportDashboardType.OBJECT,
  });
};

export const useReportComparisonDashboardFilterObjects = ({
  dashboardId,
}: {
  dashboardId: string;
}) => {
  return useReportDashboardFilterObjects({
    dashboardId,
    dashboardType: ReportDashboardType.COMPARISON_MODE,
  });
};
