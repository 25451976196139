import { LeClassification } from 'bundles/Shared/entities/leClasssification';
import { Permitted } from 'bundles/Shared/entities/permissions';
import { ReportSegment } from 'bundles/Shared/entities/segment/model';
import { HistoricalReviewTableWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable';
import {
  KpiCardWidgetConfig,
  KpiCardWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiCard';

import { UnitTypeTableWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/model';
import {
  KpiTableSingleDateWidgetConfigDto,
  KpiTableWidgetConfigDto,
} from '@/shared/api/dashboardsSettingsGeneratedApi';
import { XYChartWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/model';

export enum ReportDashboardStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}

export enum ReportDashboardType {
  EAGLE_EYE = 'eagle_eye',
  OBJECT = 'object_level',
  COMPARISON_MODE = 'comparison_mode',
  /**
   * TODO update to `REPORT_BUILDER_TEMPLATE_OBJECT_LEVEL`
   */
  REPORT_BUILDER_TEMPLATE = 'report_builder_template',
  REPORT_BUILDER_TEMPLATE_EAGLE_EYE = 'report_builder_template_eagle_eye',
}

export type DashboardTypesExcludingReportBuilderTemplate = FlattenEnum<
  | ReportDashboardType.EAGLE_EYE
  | ReportDashboardType.OBJECT
  | ReportDashboardType.COMPARISON_MODE
>;

export type EagleEyeBoard = {
  id: string;
  name: string;
  sections: ReportEagleEyeDashboardSections[];
  slug: string;
};

export type ReportEagleEyeDashboard = {
  id: string;
  name: string;
  slug: string;
  assets: ReportDashboardAsset[];
  excludedLegalEntities: ReportDashboardLegalEntity[];
  status: ReportDashboardStatus | `${ReportDashboardStatus}`;
  sections: ReportEagleEyeDashboardSections[];
  boards: EagleEyeBoard[];
  segments: ReportDashboardSegment[];
  description?: string | null;
};

export type ReportObjectDashboard = {
  id: string;
  name: string;
  excludedLegalEntities: ReportDashboardLegalEntity[];
  status: FlattenEnum<ReportDashboardStatus>;
  assets: ReportDashboardAsset[];
  permitted: Permitted;
  reportBuilderTemplate: {
    id: string;
    name: string;
  } | null;
};

export type ReportDashboardFilterSet = {
  id: string;
  name: string;
  assets: ReportDashboardAsset[];
  segments: ReportDashboardSegment[];
  excludedLegalEntities: ReportDashboardLegalEntity[];
  legalEntities: ReportDashboardLegalEntity[];
};

export type ReportDashboardAsset = {
  id: number;
  name: string;
  legalEntities: ReportDashboardLegalEntity[];
};

export type ReportDashboardEagleEyeAsset = {
  id: number;
  name: string;
  legalEntities: ReportDashboardLegalEntity[];
};

export type ReportDashboardLegalEntity = {
  id: string;
  name: string;
  classification?: LeClassification | null;
};

export type ReportDashboardSegment = Pick<ReportSegment, 'id' | 'title'> & {
  legalEntities: ReportDashboardLegalEntity[];
};

export type ReportDashboardPeriod = DateString;

export type ReportDashboardSectionPosition = {
  x: number;
  y: number;
  w: number;
  h: number;
  maxH?: number;
  maxW?: number;
  minH?: number;
  minW?: number;
  color?: string | null;
};

export type ReportDashboardSectionPositionLayout =
  ReportDashboardSectionPositionWithId[];

export type ReportDashboardSectionPositionLayouts = Record<
  'lg',
  ReportDashboardSectionPositionLayout
>;

export type ReportDashboardSectionPositionWithId =
  ReportDashboardSectionPosition & {
    i: ReportEagleEyeDashboardSection['id'];
    type: AllWidgetTypes;
  };

export const EAGLE_EYE_DASHBOARD_SECTION_TYPE = {
  KPI_CARD: 'kpi_card',
  KPI_TABLE: 'kpi_table',
  KPI_TABLE_SINGLE_DATE: 'kpi_table_single_date',
  XY_CHART_SINGLE_KPI: 'xy_chart_single_kpi',
  GLOBAL_LEASE_TABLE: 'global_lease_table',
} as const;

export const OBJECT_DASHBOARD_SECTION_TYPE = {
  HISTORICAL_REVIEW_TABLE: 'historical_review_table',
  DELINQUENCY_TRACKER_TABLE: 'delinquency_tracker_table',
  DELINQUENCY_AVERAGE_COLUMN_CHART: 'delinquency_average_column_chart',
  DELINQUENCY_SNAPSHOT_COLUMN_CHART: 'delinquency_snapshot_column_chart',
  RECENT_RENT_ROLL_TABLE: 'recent_rent_roll_table',
  XY_CHART: 'xy_chart',
  AVERAGE_ACTUAL_RENT_CHART: 'average_actual_rent_chart',
  UNIT_MIX_SUMMARY_TABLE: 'unit_mix_summary_table',
  KPI_CARD: 'kpi_card',
  FINANCIAL_TABLE_SINGLE_DATE: 'financial_table_single_date',
  MASTER_UNIT_TABLE: 'master_unit_table',
  UNIT_TYPE_TABLE: 'unit_type_table',
  GLOBAL_LEASE_TABLE: 'global_lease_table',
} as const;

export const COMPARISON_DASHBOARD_SECTION_TYPE = {
  FINANCIAL_TABLE_SINGLE_DATE: 'financial_table_single_date',
  FINANCIAL_TABLE_SINGLE_PERIOD: 'financial_table_single_period',
} as const;

export const REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE = {
  KPI_CARD: 'kpi_card',
  DELINQUENCY_TRACKER_TABLE: 'delinquency_tracker_table',
  RECENT_RENT_ROLL_TABLE: 'recent_rent_roll_table',
  HISTORICAL_TABLE: 'historical_review_table',
  TEXT_AREA: 'text_area',
  FINANCIAL_TABLE_SINGLE_DATE: 'financial_table_single_date',
  FINANCIAL_TABLE_SINGLE_PERIOD: 'financial_table_single_period',
  UNIT_MIX_SUMMARY_TABLE: 'unit_mix_summary_table',
  MEDIA: 'media',
  XY_CHART: 'xy_chart',
  UNIT_TYPE_TABLE: 'unit_type_table',
  GLOBAL_LEASE_TABLE: 'global_lease_table',
} as const;

export const REPORT_BUILDER_TEMPLATE_EAGLE_EYE_WIDGET_SECTION_TYPE = {
  TEXT_AREA: 'text_area',
  GLOBAL_LEASE_TABLE: 'global_lease_table',
  KPI_CARD: 'kpi_card',
  XY_CHART_SINGLE_KPI: 'xy_chart_single_kpi',
  KPI_TABLE: 'kpi_table',
  KPI_TABLE_SINGLE_DATE: 'kpi_table_single_date',
} as const;

export const ALL_DASHBOARD_SECTION_TYPES = {
  ...EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  ...OBJECT_DASHBOARD_SECTION_TYPE,
  ...COMPARISON_DASHBOARD_SECTION_TYPE,
  ...REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE,
  ...REPORT_BUILDER_TEMPLATE_EAGLE_EYE_WIDGET_SECTION_TYPE,
} as const;

export type EagleEyeDashboardSectionType =
  (typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE)[keyof typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE];

export type ObjectDashboardSectionTypes =
  (typeof OBJECT_DASHBOARD_SECTION_TYPE)[keyof typeof OBJECT_DASHBOARD_SECTION_TYPE];

export type ComparisonDashboardSectionTypes =
  (typeof COMPARISON_DASHBOARD_SECTION_TYPE)[keyof typeof COMPARISON_DASHBOARD_SECTION_TYPE];

export type ReportBuilderTemplateWidgetSectionTypes =
  (typeof REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE)[keyof typeof REPORT_BUILDER_TEMPLATE_WIDGET_SECTION_TYPE];

export type ReportBuilderTemplateEagleEyeWidgetTypes =
  (typeof REPORT_BUILDER_TEMPLATE_EAGLE_EYE_WIDGET_SECTION_TYPE)[keyof typeof REPORT_BUILDER_TEMPLATE_EAGLE_EYE_WIDGET_SECTION_TYPE];

export type AllReportBuilderTemplateWidgetTypes =
  | ReportBuilderTemplateWidgetSectionTypes
  | ReportBuilderTemplateEagleEyeWidgetTypes;

export type AllWidgetTypes =
  | EagleEyeDashboardSectionType
  | ObjectDashboardSectionTypes
  | ComparisonDashboardSectionTypes
  | AllReportBuilderTemplateWidgetTypes;

export type ReportObjectDashboardSection = {
  id: string;
  title: string;
  position: ReportDashboardSectionPosition;
  widgetType: ObjectDashboardSectionTypes;
  widgetConfig: Record<string, unknown>;
  recapPage: {
    name: string;
    slug: string;
  } | null;
};
export type ReportComparisonDashboardSection = {
  id: string;
  title: string;
  position: ReportDashboardSectionPosition;
  widgetType: ComparisonDashboardSectionTypes;
  widgetConfig: Record<string, unknown>;
  recapPage: {
    name: string;
    slug: string;
  } | null;
};

export type WidgetDateGranularity =
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year';

export type ReportObjectDashboardSections =
  | HistoricalReviewTableWidgetSection
  | XYChartWidgetSection
  | KpiCardWidgetSection
  | UnitTypeTableWidgetSection;

export type ReportEagleEyeDashboardSection = {
  id: string;
  title: string;
  sectionId: string;
  position: ReportDashboardSectionPosition;
  sectionType: EagleEyeDashboardSectionType;
  widgetType: EagleEyeDashboardSectionType;
  color: string | null;
  recapPage: {
    name: string;
    slug: string;
  } | null;
};

export type EagleEyeKpiCardWidgetSection = ReportEagleEyeDashboardSection & {
  widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD;
  sectionType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD;
  widgetConfig: KpiCardWidgetConfig;
};
export type EagleEyeKpiTableWidgetSection = ReportEagleEyeDashboardSection & {
  widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE;
  sectionType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE;
  widgetConfig: KpiTableWidgetConfigDto;
};
export type EagleEyeKpiTableSingleDateWidgetSection =
  ReportEagleEyeDashboardSection & {
    widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE;
    sectionType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE;
    widgetConfig: KpiTableSingleDateWidgetConfigDto;
  };

export type ReportEagleEyeDashboardSections =
  | EagleEyeKpiTableWidgetSection
  | EagleEyeKpiTableSingleDateWidgetSection
  | EagleEyeKpiCardWidgetSection;

export const isReportDashboardKpiCardSection = (
  section: ReportEagleEyeDashboardSection,
): section is EagleEyeKpiCardWidgetSection =>
  section.widgetType === EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_CARD;

export type DashboardFilterObject = {
  id: number;
  name: string;
  type: DashboardFilterObjectTypes;
  legalEntities: ReportDashboardLegalEntity[];
  leClassification?: LeClassification[];
  isFavorite?: boolean;
};

export type DashboardFilterObjectTypes = 'asset' | 'segment' | 'fund';

export type PeriodShiftType =
  | 'days'
  | 'weeks'
  | 'months'
  | 'quarters'
  | 'years';

export type TrailingPeriodType = {
  type: 'month' | 'quarter' | 'day';
  amount: number;
};

export type PeriodShiftConfig = Record<PeriodShiftType, number>;
