import { ShownInfoLine } from '@/bundles/Shared/components/Table/pagination/TablePagination';
import { DashboardFilterObject } from '@/bundles/Shared/entities/dashboard/model/types/types';
import { DashboardFilterObjectIcon } from '@/bundles/Shared/entities/dashboard/ui/DashboardFilterObjectIcon';
import LegalEntitiesIconWithTooltip from '@/bundles/Shared/entities/legalEntity/ui/LegalEntitiesIconWithTooltip';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { SearchInput } from '@/stories/FormControls/Inputs/SearchInput/SearchInput';
import {
  IThinTabItem,
  ThinTabGroup,
} from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { cn } from '@/shared/lib/css/cn';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { partition, sortBy } from 'lodash-es';
import { Button } from '@/stories/Button/Button';
import { DEFAULT_DROPDOWN_OFFSET, Popover } from '@/stories/Popover/Popover';
import { IconsId } from 'types/sre-icons';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Checkbox from '@/stories/Checkbox/Checkbox';
import { DASHBOARD_FILTER_OBJECT_ICONS } from 'bundles/Shared/entities/dashboard/config';
import { TruncatedText } from '@/shared/ui/TruncatedText';
import { usePopoverTriggered } from '@/bundles/Shared/components/LeftSidebar/hooks/usePopoverTriggered';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';

type ReportDashboardObjectPickerContextValue<
  Multi extends 'single' | 'multi' = 'single',
> = {
  value: Multi extends 'single'
    ? DashboardFilterObject | null
    : DashboardFilterObject[];
  onChange: (
    value: Multi extends 'single'
      ? DashboardFilterObject
      : DashboardFilterObject[],
  ) => void;
  objectTypes: ReportObjectPickerObjectTypes[];
  defaultObjectType: ReportObjectPickerObjectTypes | null;
  selectedObjectType: ReportObjectPickerObjectTypes | null;
  setSelectedObjectType: (
    selectedObjectType: ReportObjectPickerObjectTypes | null,
  ) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  options: DashboardFilterObject[];
  isSelected: (option: DashboardFilterObject) => boolean;
  renderObjectListItemAction?: (
    option: DashboardFilterObject,
  ) => React.ReactNode;
};

type ReportDashboardObjectPickerProps = {
  options: DashboardFilterObject[];
  objectTypes?: ReportObjectPickerObjectTypes[];
  defaultObjectType?: ReportObjectPickerObjectTypes | null;
  renderObjectListItemAction?: (
    option: DashboardFilterObject,
  ) => React.ReactNode;
  bottomPanelSlot?: React.ReactNode;
};

// todo replace with @radix-ui/react-context
const ReportDashboardObjectPickerContext =
  React.createContext<ReportDashboardObjectPickerContextValue>({
    value: null,
    onChange: () => {},
    objectTypes: ['asset', 'favorites', 'all'],
    defaultObjectType: null,
    selectedObjectType: null,
    setSelectedObjectType: () => {},
    searchText: '',
    setSearchText: () => {},
    options: [],
    renderObjectListItemAction: () => null,
    isSelected: () => false,
  });

const useReportDashboardObjectPickerContext = <
  Multi extends 'single' | 'multi' = 'single',
>() => {
  return useContext<ReportDashboardObjectPickerContextValue<Multi>>(
    ReportDashboardObjectPickerContext as React.Context<
      ReportDashboardObjectPickerContextValue<Multi>
    >,
  );
};

const useReportDashboardObjectPickerOptions = () => {
  const { selectedObjectType, searchText, options, isSelected } =
    useReportDashboardObjectPickerContext();

  // don't pass isSelected as dep, we sort only on initial mount
  const initialMountSortIndexMap = useMemo(() => {
    // Sort options by name, but put the selected value first if it exists
    const sortedByName = sortBy(options, 'name');
    const [selectedOption, otherOptions] = partition(sortedByName, (option) =>
      isSelected(option),
    );

    return new Map(
      [...selectedOption, ...otherOptions].map((option, index) => [
        option.id,
        index,
      ]),
    );
  }, []);

  const filteredOptions = useMemo(() => {
    return options
      .toSorted((a, b) => {
        const aIndex = initialMountSortIndexMap.get(a.id);
        const bIndex = initialMountSortIndexMap.get(b.id);
        return (aIndex ?? 0) - (bIndex ?? 0);
      })
      .filter((option) => {
        const matchesFavorite =
          selectedObjectType === 'favorites' && option.isFavorite;
        const matchesObjectType =
          selectedObjectType == null || option.type === selectedObjectType;
        const matchesSearchText =
          searchText === '' ||
          option.name.toLowerCase().includes(searchText.toLowerCase());

        return (matchesObjectType && matchesSearchText) || matchesFavorite;
      });
  }, [options, initialMountSortIndexMap, selectedObjectType, searchText]);

  return filteredOptions;
};

const ReportDashboardObjectPicker = <
  Multi extends 'single' | 'multi' = 'single',
>({
  children,
  value,
  defaultObjectType,
  onChange,
  objectTypes,
  options,
  renderObjectListItemAction,
  isSelected,
}: React.PropsWithChildren<
  Pick<
    ReportDashboardObjectPickerContextValue<Multi>,
    | 'value'
    | 'onChange'
    | 'objectTypes'
    | 'defaultObjectType'
    | 'options'
    | 'renderObjectListItemAction'
    | 'isSelected'
  >
>) => {
  const [searchText, setSearchText] = useState('');
  const [selectedObjectType, setSelectedObjectType] =
    useState<ReportObjectPickerObjectTypes | null>(defaultObjectType);

  const contextValue = useMemo(
    () => ({
      value,
      onChange,
      objectTypes,
      defaultObjectType,
      selectedObjectType,
      setSelectedObjectType,
      searchText,
      setSearchText,
      options,
      renderObjectListItemAction,
      isSelected,
    }),
    [
      value,
      onChange,
      objectTypes,
      selectedObjectType,
      setSelectedObjectType,
      searchText,
      setSearchText,
      options,
      defaultObjectType,
      renderObjectListItemAction,
      isSelected,
    ],
  );
  return (
    <ReportDashboardObjectPickerContext.Provider value={contextValue}>
      {children}
    </ReportDashboardObjectPickerContext.Provider>
  );
};

export const ReportDasbhoardObjectPickerLayout = ({
  children,
  className,
}: React.PropsWithChildren & PropsWithClassName) => {
  return (
    <div
      className={cn(
        'flex flex-col  divide-y divide-solid divide-neutral-200',
        className,
      )}
    >
      {children}
    </div>
  );
};

const ReportDasbhoardObjectPickerLayoutHeader = ({
  children,
}: React.PropsWithChildren) => {
  return <div className="flex flex-col gap-4 p-2">{children}</div>;
};

export const ReportDasbhoardObjectPickerLayoutBottomPanel = ({
  children,
}: React.PropsWithChildren) => {
  return <div className="flex flex-col p-2">{children}</div>;
};

const ReportDasbhoardObjectPickerLayoutObjectList = ({
  children,
}: React.PropsWithChildren) => {
  return <div className="flex flex-col pb-2">{children}</div>;
};

const ReportDasbhoardObjectPickerLayoutObjectListEmpty = () => {
  return (
    <div className="flex flex-col pb-2">
      <NoDataOverlay
        title="No objects found"
        subTitle="Please try a different search"
      />
    </div>
  );
};

const ReportDasbhoardObjectPickerLayoutObjectListItem = ({
  children,
  selected,
  onClick,
  className,
}: React.PropsWithChildren<{ selected?: boolean }> &
  React.AllHTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'flex items-center px-4 py-2 h-10 gap-2 hover:bg-neutral-100 cursor-pointer text-neutral-900',
        {
          'bg-info-055 text-neutral-000 hover:bg-info-055': selected,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

const ReportDasbhoardObjectPickerLayoutObjectListItemText = ({
  text,
  className,
}: React.ComponentProps<typeof TruncatedText>) => {
  return (
    <TruncatedText text={text} className={cn('inline-regular', className)} />
  );
};

const OBJECT_TABS = [
  {
    id: 'all',
    label: 'All',
  },
  {
    id: 'asset',
    label: '',
    iconName: DASHBOARD_FILTER_OBJECT_ICONS.asset,
  },
  {
    id: 'segment',
    label: '',
    iconName: DASHBOARD_FILTER_OBJECT_ICONS.segment,
  },
  {
    id: 'fund',
    label: '',
    iconName: DASHBOARD_FILTER_OBJECT_ICONS.fund,
  },
  {
    id: 'favorites',
    label: '',
    iconName: 'favoriteFilled',
  },
] as const satisfies (IThinTabItem & {
  iconName?: IconsId;
})[];
type ReportObjectPickerObjectTypes = (typeof OBJECT_TABS)[number]['id'];

const ReportDashboardObjectPickerObjectTabs = ({
  ...props
}: Omit<React.ComponentProps<typeof ThinTabGroup>, 'items'> & {
  objectTypes?: ReportObjectPickerObjectTypes[];
}) => {
  const {
    objectTypes,
    selectedObjectType: defaultObjectType,
    setSelectedObjectType,
  } = useReportDashboardObjectPickerContext();
  return (
    <ThinTabGroup
      items={OBJECT_TABS.filter((tab) => objectTypes.includes(tab.id))}
      selectedItem={defaultObjectType ?? 'all'}
      onSelectedItemChange={(tab) =>
        setSelectedObjectType(
          tab?.id === 'all' ? null : (tab?.id as ReportObjectPickerObjectTypes),
        )
      }
      classes={{
        itemContainer: 'grow',
        item: 'w-full justify-center',
      }}
      {...props}
    />
  );
};

const ReportDashboardObjectPickerSearchInput = () => {
  const { searchText, setSearchText } = useReportDashboardObjectPickerContext();
  return (
    <SearchInput
      size="s"
      placeholder="Search"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
    />
  );
};
const ReportDashboardSingleObjectPickerObjectList = () => {
  const { onChange, renderObjectListItemAction, isSelected } =
    useReportDashboardObjectPickerContext();
  const filteredOptions = useReportDashboardObjectPickerOptions();
  if (filteredOptions.length === 0) {
    return <ReportDasbhoardObjectPickerLayoutObjectListEmpty />;
  }
  return (
    <ReportDasbhoardObjectPickerLayoutObjectList>
      {filteredOptions.map((option) => (
        <ReportDasbhoardObjectPickerLayoutObjectListItem
          key={option.id}
          selected={isSelected(option)}
          onClick={() => onChange(option)}
        >
          <DashboardFilterObjectIcon
            className={cn('text-[16px]', {
              'text-neutral-000': isSelected(option),
            })}
            type={option.type}
          />
          <ReportDasbhoardObjectPickerLayoutObjectListItemText
            text={option.name}
          />
          <GrowDiv />
          <LegalEntitiesIconWithTooltip
            className={isSelected(option) ? 'text-neutral-000' : ''}
            legalEntities={option.legalEntities}
          />
          {renderObjectListItemAction?.(option)}
        </ReportDasbhoardObjectPickerLayoutObjectListItem>
      ))}
    </ReportDasbhoardObjectPickerLayoutObjectList>
  );
};

// TODO: FE-3932 replace with selectable list
export const ReportDashboardSingleObjectPicker = ({
  options,
  value,
  onChange,
  objectTypes = ['asset', 'favorites', 'all'],
  defaultObjectType = null,
  bottomPanelSlot,
  renderObjectListItemAction,
  ...props
}: {
  value: DashboardFilterObject | null;
  onChange: (value: DashboardFilterObject) => void;
} & PropsWithClassName &
  ReportDashboardObjectPickerProps) => {
  const isSelected = useCallback(
    (option: DashboardFilterObject) => option.id === value?.id,
    [value],
  );
  return (
    <ReportDashboardObjectPicker
      value={value}
      onChange={onChange}
      objectTypes={objectTypes}
      defaultObjectType={defaultObjectType}
      options={options}
      renderObjectListItemAction={renderObjectListItemAction}
      isSelected={isSelected}
    >
      <ReportDasbhoardObjectPickerLayout className={props.className}>
        <ReportDasbhoardObjectPickerLayoutHeader>
          <ReportDashboardObjectPickerSearchInput />
          <ReportDashboardObjectPickerObjectTabs />
          <ShownInfoLine totalSize={options.length} name="Object" />
        </ReportDasbhoardObjectPickerLayoutHeader>
        <OverlayScrollbarsComponent>
          <ReportDashboardSingleObjectPickerObjectList />
        </OverlayScrollbarsComponent>
        {bottomPanelSlot}
      </ReportDasbhoardObjectPickerLayout>
    </ReportDashboardObjectPicker>
  );
};

export const ReportDashboardMultiObjectPickerList = () => {
  const { value, onChange, renderObjectListItemAction, isSelected } =
    useReportDashboardObjectPickerContext<'multi'>();
  const options = useReportDashboardObjectPickerOptions();

  const handleChange = (option: DashboardFilterObject) => {
    onChange(
      isSelected(option)
        ? value.filter((v) => v.id !== option.id)
        : [...value, option],
    );
  };

  const [lastSelectedId, setLastSelectedId] = useState<number | null>(null);

  const handleClick = (
    option: DashboardFilterObject,
    event: React.MouseEvent,
  ) => {
    if (event.shiftKey && lastSelectedId !== null) {
      // Find indices of last selected and current option
      const lastSelectedIndex = options.findIndex(
        (opt) => opt.id === lastSelectedId,
      );
      const currentIndex = options.findIndex((opt) => opt.id === option.id);

      if (lastSelectedIndex !== -1 && currentIndex !== -1) {
        // Determine range to select
        const start = Math.min(lastSelectedIndex, currentIndex);
        const end = Math.max(lastSelectedIndex, currentIndex);

        // Get all options in the range
        const rangeOptions = options.slice(start, end + 1);

        // Create new selection by adding range options that aren't already selected
        const newSelection = [...value];
        rangeOptions.forEach((opt) => {
          if (!newSelection.some((v) => v.id === opt.id)) {
            newSelection.push(opt);
          }
        });

        onChange(newSelection);
      }
    } else {
      // Regular toggle selection
      handleChange(option);
      setLastSelectedId(option.id);
    }
  };

  if (options.length === 0) {
    return <ReportDasbhoardObjectPickerLayoutObjectListEmpty />;
  }

  return (
    <ReportDasbhoardObjectPickerLayoutObjectList>
      {options.map((option) => (
        <ReportDasbhoardObjectPickerLayoutObjectListItem
          key={option.id}
          onClick={(event) => handleClick(option, event)}
          className="select-none [&>*]:select-none"
        >
          <Checkbox noInnerLabel checked={isSelected(option)} />
          <DashboardFilterObjectIcon
            className="text-[16px]"
            type={option.type}
          />
          <ReportDasbhoardObjectPickerLayoutObjectListItemText
            text={option.name}
          />
          <GrowDiv />
          <LegalEntitiesIconWithTooltip legalEntities={option.legalEntities} />
          {renderObjectListItemAction?.(option)}
        </ReportDasbhoardObjectPickerLayoutObjectListItem>
      ))}
    </ReportDasbhoardObjectPickerLayoutObjectList>
  );
};

const ReportDashboardMultiObjectPickerSelectionHeader = () => {
  const { value, onChange } = useReportDashboardObjectPickerContext<'multi'>();
  const filteredOptions = useReportDashboardObjectPickerOptions();

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked ? filteredOptions : []);
  };

  const filteredOptionIds = filteredOptions.map((option) => option.id);
  const selectedOptionIds = value.map((option) => option.id);
  const filteredSelectedOptionIds = filteredOptionIds.filter((id) =>
    selectedOptionIds.includes(id),
  );

  const someSelected = filteredSelectedOptionIds.length > 0;

  const isAllSelected =
    filteredSelectedOptionIds.length > 0 &&
    filteredSelectedOptionIds.length === filteredOptions.length;

  return (
    <div className="flex items-center gap-2 pl-2">
      <Checkbox
        indeterminate={someSelected}
        checked={isAllSelected}
        onChange={handleSelectAll}
      />
      <ShownInfoLine totalSize={filteredOptions.length} name="Object" />
    </div>
  );
};

export const ReportDashboardMultiObjectPicker = ({
  value,
  onChange,
  objectTypes = ['asset', 'favorites', 'all'],
  defaultObjectType = null,
  options,
  bottomPanelSlot,
  renderObjectListItemAction,
  ...props
}: ReportDashboardObjectPickerProps & {
  value: DashboardFilterObject[];
  onChange: (value: DashboardFilterObject[]) => void;
} & PropsWithClassName) => {
  const isSelected = useCallback(
    (option: DashboardFilterObject) => value.some((v) => v.id === option.id),
    [value],
  );
  return (
    <ReportDashboardObjectPicker<'multi'>
      value={value}
      onChange={onChange}
      objectTypes={objectTypes}
      defaultObjectType={defaultObjectType}
      options={options}
      renderObjectListItemAction={renderObjectListItemAction}
      isSelected={isSelected}
    >
      <ReportDasbhoardObjectPickerLayout className={props.className}>
        <ReportDasbhoardObjectPickerLayoutHeader>
          <ReportDashboardObjectPickerSearchInput />
          <ReportDashboardObjectPickerObjectTabs />
          <ReportDashboardMultiObjectPickerSelectionHeader />
        </ReportDasbhoardObjectPickerLayoutHeader>
        <OverlayScrollbarsComponent>
          <ReportDashboardMultiObjectPickerList />
        </OverlayScrollbarsComponent>
        {bottomPanelSlot}
      </ReportDasbhoardObjectPickerLayout>
    </ReportDashboardObjectPicker>
  );
};

export const ReportDashboardObjectPickerTrigger = ({
  ...props
}: React.ComponentProps<typeof Button>) => {
  return (
    <Button
      size="s"
      variant="secondary"
      className="!text-info-055 px-2"
      iconName="bottom"
      iconPosition="right"
      {...props}
    />
  );
};

export const ReportDashboardObjectPickerPopover = ({
  className,
  template,
  ...props
}: React.ComponentProps<typeof Popover>) => {
  // unmount template when visible is false
  const { triggered, ...triggerProps } = usePopoverTriggered();
  return (
    <Popover
      trigger="click"
      className={cn('p-0 w-[380px] ', className)}
      maxWidth="380px"
      hiddenArrow
      placement="bottom-start"
      offset={DEFAULT_DROPDOWN_OFFSET}
      appendToBody
      template={triggered && template}
      {...triggerProps}
      {...props}
    />
  );
};

export const ReportDashboardSingleObjectPickerDropdown = ({
  ...props
}: React.ComponentProps<typeof ReportDashboardSingleObjectPicker>) => {
  return (
    <ReportDashboardObjectPickerPopover
      template={
        <ReportDashboardSingleObjectPicker
          className="max-h-[800px]"
          {...props}
        />
      }
    >
      <ReportDashboardObjectPickerTrigger>
        {props.value ? (
          <>
            <span className="text-neutral-550">Object:</span> {props.value.name}
          </>
        ) : (
          'Select Object'
        )}
      </ReportDashboardObjectPickerTrigger>
    </ReportDashboardObjectPickerPopover>
  );
};

export const ReportDashboardMultiObjectPickerDropdown = ({
  ...props
}: React.ComponentProps<typeof ReportDashboardMultiObjectPicker>) => {
  return (
    <ReportDashboardObjectPickerPopover
      template={
        <ReportDashboardMultiObjectPicker
          className="max-h-[800px]"
          {...props}
        />
      }
    >
      <ReportDashboardObjectPickerTrigger>
        {props.value.length > 0 ? (
          <>
            <span className="text-neutral-550">Objects:</span>{' '}
            {props.value.length} of {props.options.length}
          </>
        ) : (
          'Select Objects'
        )}
      </ReportDashboardObjectPickerTrigger>
    </ReportDashboardObjectPickerPopover>
  );
};
