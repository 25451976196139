import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories/Icon/Icon';

export function LegalEntitiesCounter({
  legalEntityCount,
  className,
}: {
  legalEntityCount: number;
  className?: string;
}) {
  return (
    <div className={cn('flex items-center text-light-60', className)}>
      <Icon iconName="entities" />
      <span
        className={cn(
          'secondary-regular flex w-[18px] items-center justify-center ',
          legalEntityCount > 9 && 'w-[24px]',
          legalEntityCount > 99 && 'w-[32px]',
        )}
      >
        {legalEntityCount}
      </span>
    </div>
  );
}
