import {
  LEAVE_DASHBOARD_CONFIRM_FUNC_PROPS,
  WIDGET_SETTINGS_BUTTON_TOOLTIP,
} from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import { SettingsReportObjectDashboardDateFilter } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportDashboardDateFilter';
import { useAddDashboardWidgetSection } from '@/bundles/Shared/features/dashboard/addWidget/lib';
import { useDashboardExportPDFFeature } from '@/bundles/Shared/features/dashboard/exportSettings/lib';
import { DashboardSectionRemoveButton } from '@/bundles/Shared/features/dashboard/sectionActions/ui/DashboardSectionRemoveButton';
import { OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Link, useNavigate } from '@reach/router';
import { orderDashboardSections } from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import {
  DashboardLayout,
  ObjectLevelDashboardLayout,
} from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { buildLayoutsId } from 'bundles/Shared/entities/dashboard/model/slices/shared';

import {
  DashboardContext,
  findBoardByIdOrSlug,
  isObjectDashboardSectionEditable,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard/lib';

import type { DashboardContextValue } from 'bundles/Shared/entities/dashboard/lib';
import { DashboardHasChangesButton } from 'bundles/Shared/entities/dashboard/ui/DashboardHasChangesButton';
import { DashboardIcon } from 'bundles/Shared/entities/dashboard/ui/DashboardIcon';
import { DashboardSection } from 'bundles/Shared/entities/dashboard/ui/DashboardSection';
import { DashboardSettingsToggle } from 'bundles/Shared/entities/dashboard/ui/DashboardSettingsToggle';
import { DashboardStatusDropdown } from 'bundles/Shared/entities/dashboard/ui/DashboardStatusDropdown';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard/model/types/types';
import type {
  ObjectDashboardSectionTypes,
  ReportObjectDashboardSection,
} from 'bundles/Shared/entities/dashboard/model/types/types';
import { useGetApiSettingsReportObjectDashboardsByIdQuery } from '@/shared/api/dashboardSettingsEnhancedApi';
import { selectReportDashboardLayoutDirty } from 'bundles/Shared/entities/dashboard/model/slices/layouts';
import { useDeleteDashboard } from 'bundles/Shared/features/dashboard/delete';
import { MoveToBoardButton } from 'bundles/Shared/features/dashboard/moveToBoard';
import { ObjectDashboardAssetFilter } from '@/bundles/Shared/features/dashboard/filter/byAsset/ui/ObjectDashboardAssetFilter';
import { useDashboardUpdateLayout } from 'bundles/Shared/features/dashboard/sectionActions';
import DashboardSectionDragButton from 'bundles/Shared/features/dashboard/sectionActions/ui/DashboardSectionDragButton';
import { ObjectDashboardUpdate } from 'bundles/Shared/features/dashboard/update/object';
import { useUpdateDashboardStatus } from 'bundles/Shared/features/dashboard/updateStatus';
import { DashboardBoards } from 'bundles/Shared/widgets/dashboard/board';
import { DashboardGridLayout } from 'bundles/Shared/widgets/dashboard/layout';
import {
  DashboardWidgetsBar,
  useDashboardWidgetsBarDroppableItem,
} from 'bundles/Shared/widgets/dashboard/widgetsBar';
import { ObjectDashboardWidget } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import React, { useMemo } from 'react';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { IconsId } from 'types/sre-icons';
import {
  useNavigateDashboardSettingsToBoard,
  useNavigateToFirstAssetAndBoardEffect,
  useNavigateToWidget,
  useReportSettingsDashboardScreenParams,
  useReportSettingsDashboardScreenQueryParams,
} from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import { DashboardBoardNavigationEditable } from '@/bundles/Shared/widgets/dashboard/board/ui/navigation/DashboardBoardNavigationEditable';
import { isEmpty } from 'lodash-es';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { selectReportObjectDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/objectSlice';

export const FilterBlock = ({
  iconName,
  value,
  children,
  label,
}: React.PropsWithChildren<{
  iconName: IconsId;
  label: string;
  value: string;
}>) => {
  return (
    <div className="flex items-center justify-between gap-4 !rounded-lg bg-neutral-000 py-1 pl-2 pr-4">
      <div className="flex items-center gap-2">
        <Icon
          className="!rounded-lg bg-neutral-100 p-1.5"
          iconName={iconName}
        />
        <div className="flex flex-col">
          <span className="secondary-semibold text-neutral-550">{label}</span>
          <span className="inline-semibold text-neutral-800">{value}</span>
        </div>
      </div>
      {children}
    </div>
  );
};

export function SettingsReportObjectLevelDashboard() {
  const navigate = useNavigate();
  const { confirm } = useModal();
  const { dashboardId, boardId, boardSlug } = useDashboardContext();
  const { assetId } = useReportSettingsDashboardScreenQueryParams();

  const [deleteDashboard] = useDeleteDashboard(ReportDashboardType.OBJECT);

  const { data } = useGetApiSettingsReportObjectDashboardsByIdQuery({
    id: dashboardId,
  });
  const selectedBoard = findBoardByIdOrSlug(
    data?.boards ?? [],
    boardSlug ?? '',
  );

  const viewDashboardUrl = generateUrl(ROUTES_ROOT.report.dashboards.fullPath, {
    pathParams: {
      dashboardId: data?.slug ?? '',
    },
    queryParams: {
      assetId,
      boardId: boardSlug ?? '',
    },
  });

  const navigateToWidget = useNavigateToWidget();
  const navigateToBoard = useNavigateDashboardSettingsToBoard();

  const isLayoutDirty = useAppSelector(
    selectReportDashboardLayoutDirty(
      {
        dashboardId,
        boardId,
      },
      selectReportObjectDashboardMetadataById,
      {
        withoutColor: true,
      },
    ),
  );
  const moveLayout = useDashboardUpdateLayout();
  const updateDashboardStatus = useUpdateDashboardStatus();
  const boardState = useAppSelector((state) =>
    selectReportObjectDashboardMetadataById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  );
  const [addObjectDashboardWidget] = useAddDashboardWidgetSection();
  const {
    handleDragStart,
    droppableItem,
    handleDrop: handleWidgetsBarDrop,
    droppableItemPlaceholderProps,
  } = useDashboardWidgetsBarDroppableItem();

  useNavigateToFirstAssetAndBoardEffect({
    assets: data?.assets ?? [],
    boards: data?.boards ?? [],
  });

  const handleDrop: React.ComponentProps<
    typeof DashboardGridLayout
  >['onDrop'] = async (layout, layoutItem, _event) => {
    const type = layoutItem.i as ObjectDashboardSectionTypes;
    const res = await addObjectDashboardWidget({
      layout,
      layoutItem,
      type,
      boardId,
    });

    handleWidgetsBarDrop();

    if ('error' in res) {
      return;
    }
  };

  const handlePreviewClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isLayoutDirty) {
      const res = await confirm(LEAVE_DASHBOARD_CONFIRM_FUNC_PROPS);
      if (res) {
        await moveLayout({
          layout: boardState!.layouts!.lg,
          boardId,
        });
      }
    }
    navigate(viewDashboardUrl);
  };

  const dashboardState = useAppSelector((state) =>
    selectReportObjectDashboardMetadataById(state, dashboardId ?? ''),
  );
  const { date } = dashboardState ?? {};

  const dashboardExportPDFFeature = useDashboardExportPDFFeature({
    assetId,
    date,
    reportBuilderTemplate: data?.reportBuilderTemplate ?? null,
  });

  const generateLayout = () => {
    // check boardId is empty, sometimes race condition happens and boardId is not set yet
    if (data === undefined || selectedBoard === undefined || isEmpty(boardId)) {
      return null;
    }

    if (selectedBoard.sections?.length === 0 && droppableItem != null) {
      return <div {...droppableItemPlaceholderProps} />;
    }

    return orderDashboardSections(selectedBoard.sections).map((s) => {
      const section = s as unknown as ReportObjectDashboardSection;
      if (OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP[section.widgetType] == null) {
        return null;
      }
      const { title, icon } =
        OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP[section.widgetType];
      const layoutItem = boardState?.layouts?.lg.find(
        (item) => item.i === section.id,
      );
      const isEditable = isObjectDashboardSectionEditable(section);
      const handleEdit = isEditable
        ? () => {
            navigateToWidget({
              widgetId: section.id,
            });
          }
        : undefined;

      return (
        <div
          className={cn(section.position.w === 1 && 'col-span-2')}
          data-grid={layoutItem}
          key={section.id}
        >
          <DashboardSection
            id={section.id}
            className="h-full"
            panel={
              <div className="flex items-center gap-2">
                <DashboardSectionDragButton />
                <div className="flex gap-1">
                  <Icon iconName={icon} />
                  <span className="secondary-semibold uppercase text-neutral-500">
                    {title}
                  </span>
                </div>
                <GrowDiv />
                <div className="flex items-center gap-2">
                  {isEditable && (
                    <IconButton
                      size="s"
                      iconName="edit"
                      onClick={handleEdit}
                      tooltipProps={{
                        mainText: WIDGET_SETTINGS_BUTTON_TOOLTIP,
                      }}
                    />
                  )}
                  <MoveToBoardButton
                    boardId={boardId}
                    sectionId={section.id}
                    boards={data.boards}
                  />
                  <DashboardSectionRemoveButton
                    sectionId={section.id}
                    boardId={boardId}
                    layout={boardState!.layouts!.lg}
                  />
                </div>
              </div>
            }
          >
            <ObjectDashboardWidget
              dashboardId={dashboardId}
              widgetSection={section}
              mode="edit"
              selectedAssetId={Number(assetId)}
              boardId={boardId}
              assets={data?.assets ?? []}
            />
          </DashboardSection>
        </div>
      );
    });
  };

  if (data === undefined) {
    return null;
  }

  return (
    <ObjectLevelDashboardLayout>
      <ObjectLevelDashboardLayout.Sidebar>
        <DashboardLayout.StickyHeader className="bg-neutral-100">
          <DashboardLayout.Header className="h-auto">
            <DashboardLayout.Header.Title
              title={data.name}
              subtitle={
                <>
                  <DashboardIcon iconName="objectLevelDashboard" />
                  Object Level
                </>
              }
            />
            <div className="flex items-center gap-2">
              <IconButton
                iconName="trash"
                onClick={async () => {
                  const res = await deleteDashboard(dashboardId);
                  if (!res) return;
                  navigate(-1);
                }}
              />
              <DashboardStatusDropdown
                onChange={(status) => {
                  updateDashboardStatus({
                    dashboardId,
                    status,
                  });
                }}
                value={data.status}
              />
            </div>
            <GrowDiv />
            <Link onClick={handlePreviewClick} to={viewDashboardUrl}>
              <DashboardSettingsToggle isActive />
            </Link>
          </DashboardLayout.Header>
        </DashboardLayout.StickyHeader>
        <div className="px-6 pt-2">
          <ObjectDashboardUpdate model={data} />
        </div>
      </ObjectLevelDashboardLayout.Sidebar>
      <ObjectLevelDashboardLayout.Body>
        <DashboardLayout.StickyHeader>
          <DashboardLayout.Header className="grid-column-2">
            <ObjectDashboardAssetFilter assetId={Number(assetId)} />
            <SettingsReportObjectDashboardDateFilter />
            <GrowDiv />
            <DashboardHasChangesButton
              isDirty={isLayoutDirty}
              onClick={() => {
                moveLayout({
                  layout: boardState!.layouts!.lg,
                  boardId,
                });
              }}
            />
            <dashboardExportPDFFeature.Button />
          </DashboardLayout.Header>
          {data && (
            <div className="px-6 pb-2">
              <DashboardBoardNavigationEditable
                boards={data.boards}
                selectedBoardId={boardId}
                onBoardChange={navigateToBoard}
              />
            </div>
          )}
        </DashboardLayout.StickyHeader>
        <DashboardLayout.Body className="p-0">
          <DashboardBoards>
            <DashboardLayout.Grid>
              <DashboardGridLayout
                originalLayout={boardState?.layouts?.lg}
                editable
                droppingItem={droppableItem ?? undefined}
                isDroppable
                onDrop={handleDrop}
              >
                {generateLayout()}
              </DashboardGridLayout>
            </DashboardLayout.Grid>
          </DashboardBoards>
        </DashboardLayout.Body>
      </ObjectLevelDashboardLayout.Body>
      <DashboardWidgetsBar
        layout={boardState?.layouts}
        onDragStart={handleDragStart}
      />
    </ObjectLevelDashboardLayout>
  );
}

export const SettingsReportObjectLevelDashboardPage = () => {
  const { dashboardId: dashboardSlug } =
    useReportSettingsDashboardScreenParams();
  const { boardId: boardSlug } = useReportSettingsDashboardScreenQueryParams();

  const { data, isLoading, isUninitialized } =
    useGetApiSettingsReportObjectDashboardsByIdQuery({
      id: dashboardSlug,
    });
  const selectedBoard = findBoardByIdOrSlug(
    data?.boards ?? [],
    boardSlug ?? '',
  );
  const boardId = selectedBoard?.id ?? '';
  const dashboardContextValue = useMemo<DashboardContextValue>(
    () => ({
      dashboardId: data?.id ?? '',
      dashboardSlug: data?.slug ?? '',
      dashboardType: ReportDashboardType.OBJECT,
      boardId,
      boardSlug: boardSlug ?? '',
    }),
    [data, boardId],
  );

  if (isLoading || isUninitialized) {
    return <DashboardLayout.LoadingPlaceholder />;
  }

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <SettingsReportObjectLevelDashboard />
    </DashboardContext.Provider>
  );
};
