import { DASHBOARD_FILTER_OBJECT_ICONS } from '@/bundles/Shared/entities/dashboard/config';
import { DashboardFilterObjectTypes } from '@/bundles/Shared/entities/dashboard/model';
import { cn } from '@/shared/lib/css/cn';

import { Icon } from '@/stories/Icon/Icon';

export const DashboardFilterObjectIcon = ({
  type,
  className,
}: {
  type: DashboardFilterObjectTypes;
} & PropsWithClassName) => {
  return (
    <Icon
      className={cn('text-[24px] text-neutral-550', className)}
      iconName={DASHBOARD_FILTER_OBJECT_ICONS[type]}
    />
  );
};
