import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { Popover } from 'stories/Popover/Popover';
import { Tag } from 'stories/Tags/Tag/Tag';
import React from 'react';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import { useCustomer } from 'lib/customers';
import AddItemFromList from 'bundles/Shared/components/AddItemFromList';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import { IUserWithAccessLevel } from 'bundles/UserManagement/components/EditUserModalComponents/types';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { InvestmentEntity } from 'bundles/Settings/components/Portal/InvestmentEntities/api/investmentEntitiesApi';
import { CoreFiltersUsers } from 'bundles/UserManagement/api/settingsCoreLegalEntitiesApi';
import { VendorEntity } from 'bundles/Shared/entities/investmentEntities/model';
import pluralize from 'pluralize';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import { Label } from 'stories/Label/Label';
import { PseudoLink } from 'stories/PseudoLink/PseudoLink';
import { CssVar } from '@/shared/config/cssVar';

const onUserSelect = (user, settings, setSettings) => {
  const users = !settings.filters.users.some((v) => v.id === user.id)
    ? [...settings.filters.users, user]
    : [...settings.filters.users].filter(({ id }) => id !== user.id);

  setSettings({
    ...settings,
    filters: { ...settings.filters, users },
  });
};

const onVendorSelect = (vendor, settings, setSettings) => {
  const vendorEntities = !settings.filters.vendorEntities.some(
    (v) => v.id === vendor.id,
  )
    ? [...settings.filters.vendorEntities, vendor]
    : [...settings.filters.vendorEntities].filter(({ id }) => id !== vendor.id);

  setSettings({
    ...settings,
    filters: { ...settings.filters, vendorEntities },
  });
};

const onResetUserFilter = (settings, setSettings) => {
  setSettings({
    ...settings,
    filters: { ...settings.filters, users: [] },
  });
};

const onResetVendorEntities = (settings, setSettings) => {
  setSettings({
    ...settings,
    filters: { ...settings.filters, vendorEntities: [] },
  });
};

function hasObjects(inv: InvestmentEntity) {
  return inv.capitalAssets.length > 0 || inv.capitalFunds.length > 0;
}

function hasBothObjectTypes(inv: InvestmentEntity) {
  return inv.capitalAssets.length > 0 && inv.capitalFunds.length > 0;
}

function ObjectList({
  label,
  items,
}: {
  label: string;
  items: { id: number; name: string }[];
}) {
  return (
    <div className="flex flex-col gap-2 bg-white">
      <p className="font-semibold text-neutral-900">{label}</p>
      <div className="flex flex-col gap-1">
        {items.map((item) => (
          <div key={item.id} className="flex">
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}

type Params = {
  actions: {
    edit: (item: InvestmentEntity) => void;
    destroy: (item: InvestmentEntity) => void;
    onUserClick: (user: IUserWithAccessLevel) => void;
  };
  userOptions: {
    users: CoreFiltersUsers[];
    isLoading: boolean;
  };
  vendorOptions: {
    vendorEntities: VendorEntity[];
  };
};

const TagsList = ({
  items,
  emptyLabel = 'No items',
}: {
  items: string[];
  emptyLabel: string;
}) => {
  const MIN_ITEMS_FOR_ADDITIONAL_TAG = 2;

  if (items.length === 0) {
    return (
      <div className="inline-regular text-neutral-550 text-xs">
        {emptyLabel}
      </div>
    );
  }
  return (
    <div className="flex items-center gap-1">
      <Tooltip mainText={items[0]} placement="bottom-start">
        <Tag label={items[0]} className="max-w-[100px]" />
      </Tooltip>
      {items.length >= MIN_ITEMS_FOR_ADDITIONAL_TAG && (
        <Tooltip
          mainText={
            <div className="flex flex-col gap-1">
              {items.slice(1).map((item) => (
                <div key={item}>{item}</div>
              ))}
            </div>
          }
        >
          <Tag label={`+${items.length - 1}`} />
        </Tooltip>
      )}
    </div>
  );
};

const investmentEntityColumns = ({
  actions,
  userOptions: { users, isLoading: isUsersLoading },
  vendorOptions: { vendorEntities },
}: Params): IColumn<InvestmentEntity>[] => [
  {
    dataField: 'name',
    text: 'Name',
    classes: 'max-w-[200px]',
    formatter: ({ row: investmentEntity }) => (
      <div className="flex items-center gap-2 text-nowrap">
        <Icon className="text-neutral-500" iconName="bag" />
        <Tooltip
          mainText={investmentEntity.name}
          classes={{
            spanContainer: 'block text-ellipsis text-neutral-850',
          }}
        >
          {investmentEntity.name}
        </Tooltip>
      </div>
    ),
    sortable: true,
  },
  {
    dataField: 'objects',
    text: 'Objects',
    headerClasses: 'min-w-[150px]',
    sortable: true,
    formatter: ({ row: investmentEntity }) => {
      const assets = investmentEntity.capitalAssets;
      const funds = investmentEntity.capitalFunds;

      const objects = [];
      if (assets.length > 0) {
        objects.push(`${assets.length} ${pluralize('Asset', assets.length)}`);
      }
      if (funds.length > 0) {
        objects.push(`${funds.length} ${pluralize('Fund', funds.length)}`);
      }

      return (
        <div>
          {objects.length > 0 ? (
            <PseudoLink>
              <Popover
                hiddenArrow
                placement="bottom-start"
                maxWidth={420}
                template={
                  hasObjects(investmentEntity) ? (
                    <div className="flex">
                      <div className="md grid grid-cols-[max-content_1px_max-content] gap-3">
                        {investmentEntity.capitalAssets.length > 0 && (
                          <ObjectList
                            label="Assets"
                            items={investmentEntity.capitalAssets}
                          />
                        )}
                        {hasBothObjectTypes(investmentEntity) && (
                          <div className="w-1 bg-neutral-150" />
                        )}
                        {investmentEntity.capitalFunds.length > 0 && (
                          <ObjectList
                            label="Funds"
                            items={investmentEntity.capitalFunds}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="inline-regular flex text-neutral-900">
                      No active investments
                    </div>
                  )
                }
              >
                {objects.join(', ')}
              </Popover>
            </PseudoLink>
          ) : (
            <div className="inline-regular text-xs text-neutral-550">
              No Objects
            </div>
          )}
        </div>
      );
    },
  },
  {
    dataField: 'bankTaxDetails',
    text: 'Bank & Tax Details',
    headerStyle: { minWidth: '150px' },
    formatter: ({ row: investmentEntity }) => {
      const status = investmentEntity.credentialsStatus;

      const tagProps = {
        color: CssVar.neutral050,
        outline: true,
        children: 'UNKNOWN',
      };

      switch (status) {
        case 'missing':
          tagProps.children = 'NO DATA';
          tagProps.color = CssVar.danger070;
          break;
        case 'unconfirmed':
          tagProps.children = 'NOT CONFIRMED';
          tagProps.color = CssVar.attention070;
          break;
        case 'confirmed':
          tagProps.children = 'CONFIRMED';
          tagProps.color = CssVar.success070;
          break;
      }

      return <Label {...tagProps} />;
    },
  },
  {
    dataField: 'users',
    text: 'Linked Members',
    formatter: ({ row: investmentEntity }) => (
      <TagsList
        items={investmentEntity.users.map((user) => user.fullName)}
        emptyLabel="No Members"
      />
    ),
    filter: (settings, setSettings) => (
      <div className="ml-s">
        <AddItemFromList
          className="p-0"
          allItems={users}
          selectedItems={settings.filters.users}
          onSelect={(user) => onUserSelect(user, settings, setSettings)}
          mask="users"
          getMainFieldTitle={(user) => user.fullName}
          getSecondaryFieldTitle={(user) => user.role.name}
          areItemsLoading={isUsersLoading}
        >
          <UniversalFilterButton
            filtered={settings.filters.users.length > 0}
            onClose={() => onResetUserFilter(settings, setSettings)}
          />
        </AddItemFromList>
      </div>
    ),
  },

  {
    dataField: 'vendor_entities',
    text: 'Linked Vendors',
    hidden: !useCustomer().products?.includes(REPORT_PRODUCT_NAME),
    filter: (settings, setSettings) => (
      <div className="ml-s">
        <AddItemFromList
          className="p-0"
          allItems={vendorEntities}
          selectedItems={settings.filters.vendorEntities}
          onSelect={(vendor) => onVendorSelect(vendor, settings, setSettings)}
          mask="vendors"
          getMainFieldTitle={(vendor) => vendor.name}
          getSecondaryFieldTitle={(vendor) => vendor.code}
        >
          <UniversalFilterButton
            filtered={settings.filters.vendorEntities.length > 0}
            onClose={() => onResetVendorEntities(settings, setSettings)}
          />
        </AddItemFromList>
      </div>
    ),
    formatter: ({ row: investmentEntity }) => (
      <TagsList
        items={investmentEntity.vendorEntities.map((vendor) => vendor.name)}
        emptyLabel="No Vendors"
      />
    ),
  },
  {
    dataField: 'total_contributions',
    text: 'Total Contributions',
    formatter: ({ row: investmentEntity }) => (
      <CurrencyFormatter value={investmentEntity.totalContributions} />
    ),
    sortable: true,
  },
  {
    dataField: 'total_distributions',
    text: 'Total Distributions',
    formatter: ({ row: investmentEntity }) => (
      <CurrencyFormatter value={investmentEntity.totalDistributions} />
    ),
    sortable: true,
  },
  {
    dataField: 'updated',
    text: 'Updated',
    headerClasses: 'min-w-[200px]',
    formatter: ({ row: investmentEntity }) => (
      <>
        <div className="inline-regular text-neutral-850 text-sm">
          {formatUnixDate(investmentEntity.updatedAt, 'MMM DD, YYYY h:mm A')}
        </div>
        <div className="inline-regular text-neutral-550 text-xs mt-2">
          by {investmentEntity.updatedBy.fullName}
        </div>
      </>
    ),
    sortable: true,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    formatter: ({ row: investmentEntity }) => {
      const tooltipProps = {
        mainText:
          'This investment entity is linked to users or REturn and cannot be deleted',
        disabled: investmentEntity.canBeDeleted,
      };
      return (
        <div className="flex gap-2">
          <IconButton
            onClick={() => actions.destroy(investmentEntity)}
            disabled={!investmentEntity.canBeDeleted}
            tooltipProps={tooltipProps}
            iconName="trash"
          />
          <IconButton
            iconName="edit"
            onClick={() => actions.edit(investmentEntity)}
          />
        </div>
      );
    },
  },
];

export default investmentEntityColumns;
