import { reportBuilderTemplateSliceActions } from '@/bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import { FilterIconButton } from '@/bundles/Shared/features/dashboard/filter/byAsset/ui/FilterIconButton';
import { ReportDashboardFilterByObjectModal } from '@/bundles/Shared/features/dashboard/filter/byObject/ui/ReportDashboardFilterByObjectModal';
import { useReportBuilderTemplateMetaContext } from '@/entities/report/reportBuilder/context/reportBuilderTemplateMetaContext';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { FilterBlock } from 'bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportObjectLevelDashboard';
import pluralize from 'pluralize';

export function ReportBuilderTemplateEagleEyeObjectsFilter() {
  const selectedObjects = useAppSelector(
    (state) => state.reportBuilderTemplate.selectedObjects,
  );
  const dispatch = useAppDispatch();
  const { assets, segments } = useReportBuilderTemplateMetaContext();
  const { openModal } = useModal();

  const handleChange = async () => {
    const res = await openModal(ReportDashboardFilterByObjectModal, {
      assets,
      segments,
      initialSelectedObjects: selectedObjects,
      objectTypes: ['asset', 'segment'],
      isMultiple: true,
    });
    if (res == null) {
      return;
    }
    if (!Array.isArray(res)) return;

    dispatch(reportBuilderTemplateSliceActions.updateSelectedObjects(res));
  };

  return (
    <FilterBlock
      iconName="segments"
      label="Objects"
      value={
        selectedObjects.length > 0
          ? `${selectedObjects.length} ${pluralize(
              'Object',
              selectedObjects.length,
            )}`
          : 'Select Objects'
      }
    >
      <FilterIconButton onClick={handleChange} />
    </FilterBlock>
  );
}
