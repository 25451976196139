import { mapListToIds } from '@/shared/lib/listHelpers';
import { filterIncludedLegalEntities } from 'bundles/Shared/entities/dashboard/lib';
import { ObjectDashboardAssetDto } from '@/shared/api/dashboardsGeneratedApi';
import {
  useReportDashboardFromScreenParams,
  useReportDashboardScreenQueryParams,
} from '@/shared/lib/hooks/navigation/dashboardsNavitation';

export const useReportDashboardSelectedAsset = ():
  | ObjectDashboardAssetDto
  | undefined => {
  const { data } = useReportDashboardFromScreenParams();
  const { assetId } = useReportDashboardScreenQueryParams();
  const asset = data?.assets.find((a) => a.id === assetId);

  if (asset == null) return undefined;

  const excludedLegalEntityIds = mapListToIds(
    data?.excludedLegalEntities ?? [],
  );

  return {
    ...asset,
    legalEntities: asset.legalEntities.filter((le) =>
      filterIncludedLegalEntities(le, excludedLegalEntityIds),
    ),
  };
};
