import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard';
import { useDashboardFavoriteAssets } from '@/bundles/Shared/features/dashboard/filter/favorite/lib';
import FavoriteIconButton from '@/stories/ProjectCard/FavoriteIconButton';

export const ReportDashboardObjectFavoriteIconButton = ({
  dashboardId,
  assetId,
  dashboardType,
}: {
  dashboardId: string;
  assetId: number;
  dashboardType:
    | ReportDashboardType.COMPARISON_MODE
    | ReportDashboardType.OBJECT;
}) => {
  const { isItemFavorite, toggleItemFavorite } =
    useDashboardFavoriteAssets(dashboardType);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleItemFavorite({
      dashboardId,
      assetId,
    });
  };

  return (
    <FavoriteIconButton
      size="m"
      selected={isItemFavorite({
        dashboardId,
        assetId,
      })}
      onClick={handleClick}
    />
  );
};
