import { useDashboardContext } from 'bundles/Shared/entities/dashboard/lib';
import {
  ReportDashboardAssetFilter,
  ReportDashboardAssetFilterPropsProps,
} from '@/bundles/Shared/features/dashboard/filter/byAsset/ui/ReportDashboardAssetFilter';
import { useLocalStorageValue } from '@/shared/lib/hooks/useLocalStorageValue';
import Button from '@/stories/Button/Button';
import {
  MultilineAlert,
  MultilineAlertContainer,
} from '@/stories/MultilineAlert/MultilineAlert';
import { Icon } from '@/stories/Icon/Icon';
import { ReportDasbhoardObjectPickerLayoutBottomPanel } from '@/bundles/Shared/features/dashboard/filter/byObject/ui/ReportDasbhoardObjectPicker';
import { useReportComparisonDashboardFilterObjects } from '@/bundles/Shared/entities/dashboard/api';

// TODO move to shared help component
export const ShiftSelectMultilineAlert = () => {
  const [shiftClickMultiSelect, setShiftClickMultiSelect] =
    useLocalStorageValue('shiftClickMultiSelect', false);

  if (shiftClickMultiSelect) {
    return null;
  }

  return (
    <ReportDasbhoardObjectPickerLayoutBottomPanel>
      <MultilineAlertContainer
        classes={{
          body: 'flex flex-col',
        }}
        status="info"
      >
        <>
          <div className="flex w-full items-start justify-between">
            <Icon iconName="info" />
            <div className="flex flex-col gap-1">
              <span className="inline-semibold mb-xs">Shift + Click</span>
              <span>
                You can select multiple items with pressed “Shift” key.{' '}
              </span>
              <Button
                className="text-neutral-050 bg-info-055 self-start border-0"
                variant="primary"
                size="s"
                onClick={() => {
                  setShiftClickMultiSelect(true);
                }}
              >
                Got it
              </Button>
            </div>
          </div>
        </>
      </MultilineAlertContainer>
    </ReportDasbhoardObjectPickerLayoutBottomPanel>
  );
};

export const AtLeastTwoAssetsSelectedMultilineAlert = () => {
  return (
    <MultilineAlert
      message="Select at least 2 objects and refresh results."
      status="secondary"
    />
  );
};

export const ComparisonDashboardAssetFilter = (
  props: Omit<ReportDashboardAssetFilterPropsProps, 'assets'>,
) => {
  const { dashboardId } = useDashboardContext();
  const { objects } = useReportComparisonDashboardFilterObjects({
    dashboardId,
  });

  return (
    <ReportDashboardAssetFilter
      bottomPanelSlot={<ShiftSelectMultilineAlert />}
      assets={objects ?? []}
      {...props}
    />
  );
};
